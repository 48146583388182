<template>
  <section>
    <breadcrumb v-if="post" :title="$t('agencies')" :subtitle="$t('new_agency')"/>
    <breadcrumb v-else :title="$t('agencies')" :subtitle="$t('edit_agency')"/>
    <div class="is-flex is-flex-direction-column form-agency">
      <div class="is-flex ">
        <span @click="isData=true" class="tag is-data mx-10 rounded-10 cursor-pointer">{{ $t('data') }}</span>
        <span @click="isData=false" class="tag is-credentials mx-10 rounded-10 cursor-pointer">{{
            $t('credentials')
          }}</span>
      </div>
      <template v-if="isData">
        <div class="columns mt-20">
          <div class="column is-half">
            <b-field :label="`${$t('name')}*`" :custom-class="{'has-text-danger': error.name}">
              <b-input v-model="agency.name" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="`${$t('payment_groups')}*`" :custom-class="{'has-text-danger': error.paymentGroups}">
              <multiselect
                  track-by="id"
                  :max="3"
                  v-model="paymentGroupsValue"
                  :multiple="true"
                  :custom-label="currencyFromTo"
                  open-direction="bottom"
                  :options="paymentsGroupsList">
              </multiselect>
            </b-field>
            <b-field :label="`${$t('exchange_rate')}*`" :custom-class="{'has-text-danger': error.exchangeRate}">
              <b-input v-model="agency.exchangeRate" type="number" placeholder="" custom-class="full-input"></b-input>
            </b-field>

          </div>
          <div class="column is-half">
            <b-field :label="`${$t('email')}*`" :custom-class="{'has-text-danger': error.email}">
              <b-input v-model="agency.email" type="email" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="`${$t('phone')}*`" :custom-class="{'has-text-danger': error.phoneNumber}">
              <b-input v-model="agency.phoneNumber" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <div class="mt-50">
              <b-field>
                <b-switch :value="false"
                          v-model="agency.isActive"
                          :left-label="true"
                          size="is-medium"
                          type="is-green-success">
                  {{ $t('active') }}
                </b-switch>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <b-field :label="`${$t('address_number', { number: 1 })}*`"
                     :custom-class="{'has-text-danger': error.addressLine1}">
              <b-input v-model="agency.addressLine1" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="$t('address_number', { number: 2 })">
              <b-input v-model="agency.addressLine2" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="$t('address_number', { number: 3 })">
              <b-input v-model="agency.addressLine3" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>

          </div>
          <div class="column is-half">
            <div class="columns">
              <div class="column is-half">
                <b-field :label="`${$t('country')}*`" :custom-class="{'has-text-danger': error.country}">
                  <multiselect
                      v-model="country"
                      :custom-label="selectName"
                      open-direction="bottom"
                      :show-no-results="false"
                      :options="countryList">
                  </multiselect>
                </b-field>
                <b-field :label="`${$t('city')}*`" :custom-class="{'has-text-danger': error.municipalityId}">
                  <b-input v-model="agency.municipalityId" type="text" placeholder="" custom-class="full-input"></b-input>

                  <!--                  <multiselect-->
<!--                      v-model="municipality"-->
<!--                      :custom-label="selectName"-->
<!--                      open-direction="bottom"-->
<!--                      :show-no-results="false"-->
<!--                      :options="cityList">-->
<!--                  </multiselect>-->
                </b-field>

              </div>
              <div class="column is-half">
                <b-field :label="`${$t('state')}*`" :custom-class="{'has-text-danger': error.provinceId}">
                  <multiselect
                      v-model="province"
                      :custom-label="stateName"
                      open-direction="bottom"
                      :show-no-results="false"
                      :options="stateList">
                  </multiselect>
                </b-field>
                <b-field :label="`${$t('zip')}*`" :custom-class="{'has-text-danger': error.zipCode}">
                  <b-input v-model="agency.zipCode" type="text" placeholder="" custom-class="full-input"></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="columns mt-20">
          <div class="columns is-align-items-center is-flex">
            <div class="column is-4 is-align-items-center is-flex-direction-column">
              <div v-if="!avatar" @click="toggleShow" class="rounded-50x avatar cursor-pointer">
                <span class="mdi mdi-account-circle account-avatar"></span>
              </div>
              <div @click="toggleShow" v-else class="cursor-pointer">
                <img class="avatar-load" :src="avatarPreview" alt="">
              </div>
              <b-field hidden>
                <b-upload ref="pic" drag-drop native v-model="avatar">
                  <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">{{ $t('scan_firm') }}</span>
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="column is-4 is-flex is-flex-direction-column">
              <span class="help">{{ $t('avatar') }}</span>
              <span class="help">{{ $t('avatar_help') }}</span>
            </div>
          </div>
          <div class="column is-half">
            <b-field :label="`${$t('username')}*`" :custom-class="{'has-text-danger': error.username}">
              <b-input v-model="agency.email" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="`${$t('password')}*`" :custom-class="{'has-text-danger': error.password}">
              <b-input v-model="agency.password"
                       type="password"
                       required
                       :validation-message="$t('password_required')"
                       password-reveal
                       icon-clickable custom-class="full-input"></b-input>
            </b-field>
            <section v-if="pass_val && agency.password" class="is-flex is-flex-direction-column">
              <password-required/>
            </section>
          </div>
        </div>
      </template>

      <div class="is-flex is-justify-content-flex-end">
        <router-link :to="{name:'listAgency'}">
          <b-button class="btn btn-back mx-10">
            {{ $t('back') }}
          </b-button>
        </router-link>
        <b-button v-if="!pass_val" @click="save" class="btn btn-continue mx-10" type="is-primary">
          {{ $t('save') }}
        </b-button>
        <b-button v-else disabled class="btn btn-continue mx-10" type="is-primary">
          {{ $t('save') }}
        </b-button>
      </div>


    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Multiselect from 'vue-multiselect'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {notification} from "@/utils";
import {TYPE_ERROR} from "@/enum/typeError";
import PasswordRequired from "@/components/modals/password-required";


export default {
  name: "NewAgencyComponent",
  components: {PasswordRequired, Breadcrumb, Multiselect},
  data() {
    return {
      pass_val: false,
      error: {
        email: false,
        password: false,
        username: false,
        name: false,
        exchangeRate: false,
        phoneNumber: false,
        addressLine1: false,
        provinceId: false,
        municipalityId: false,
        country: false,
        paymentGroups: false,
        zipCode: false,
      },
      isData: true,
      avatar: null,
      avatarPreview: null,
      cityList: [],
      stateList: [],
      country: null,
      province: null,
      municipality: null,
      paymentGroups: [],
      paymentGroupsValue: [],
      post: true,


      agency: {
        email: null,
        password: null,
        isActive: false,
        name: null,
        exchangeRate: null, //nuevo
        identificationNumber: null,
        phoneNumber: null,
        userTypeName: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        provinceId: null,
        municipalityId: null,
        country: null,
        paymentGroups: [],
        zipCode: null,
        profilePicture: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      countryList: 'GET_COUNTRY_LIST',
      paymentsGroupsList: 'GET_PAYMENTS_GROUPS'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'SET_LOADING'
    }),
    ...mapActions({
      fetchCountry: 'FETCH_COUNTRY',
      fetchState: 'FETCH_STATES_BY_COUNTRY',
      fetchCity: 'FETCH_CITY',
      fetchAgency: 'FETCH_AGENCY',
      fetchPaymentsGroups: 'FETCH_PAYMENTS_GROUPS',
      saveAgency: 'SAVE_AGENCY',
      editAgency: 'EDIT_AGENCY',
      createUserAgency: 'CREATED_USER',
      uploadPicture: 'UPLOAD_FILE',
      goBack: 'GO_BACK',
      goTo: 'GO_TO',
    }),
    save() {
      this.agency.paymentGroups = this.paymentGroups;
      this.agency.exchangeRate = Number(this.agency.exchangeRate)
      if (this.validate()) {
        if (this.post) {
          if (this.avatar !== null) {
            this.uploadPicture({file: this.avatar})
                .then(picture => {
                  this.agency.profilePicture = picture
                  this.saveAgency(this.agency).then(() => {
                    this.goTo('listAgency')
                  })
                })
          } else {
            this.saveAgency(this.agency).then(() => {
              this.goTo('listAgency')
            })
          }

        } else {
          if (this.avatar) {
            this.uploadPicture({file: this.avatar})
                .then(picture => {
                  this.agency.profilePicture = picture
                  this.editAgency(this.agency).then(() => {
                    this.goTo('listAgency')
                  })
                })
          } else {
            this.editAgency(this.agency).then(() => {
              this.goTo('listAgency')
            })
          }
        }

      }

    },

    currencyFromTo({name, groupName, currencyFrom, currencyTo, rate, managerCommission}) {
      let names = name ? name : groupName
      let rates = managerCommission ? managerCommission.rate : rate
      return `${names} - ${currencyFrom} — ${currencyTo} - (${rates}%)`
    },
    selectName({name}) {
      return name
    },
    stateName({name}) {
      return name
    },
    validate() {
      let has_error = false;

      if (this.post) {
        if (this.agency.password === null) {
          this.error.password = true
          has_error = true
        }

        if (this.agency.username === null) {
          this.error.username = true
          has_error = true
        }
        if (this.agency.name === null) {
          this.error.name = true
          has_error = true
        }
        if (this.agency.phoneNumber === null) {
          this.error.phoneNumber = true
          has_error = true
        }
        if (this.agency.addressLine1 === null) {
          this.error.addressLine1 = true
          has_error = true
        }
        if (this.agency.country === null) {
          this.error.country = true
          has_error = true
        }
        if (this.agency.email === null) {
          this.error.email = true
          has_error = true
        }
        if (this.agency.exchangeRate === null) {
          this.error.exchangeRate = true
          has_error = true
        }
        if (this.agency.municipalityId === null) {
          this.error.municipalityId = true
          has_error = true
        }
        if (this.agency.paymentGroups.length === 0) {
          this.error.paymentGroups = true
          has_error = true
        }
        if (this.agency.provinceId === null) {
          this.error.provinceId = true
          has_error = true
        }
        if (this.agency.zipCode === null) {
          this.error.zipCode = true
          has_error = true
        }
        if (has_error) {
          notification('Llene todos los campos obligatorios', TYPE_ERROR.WARNING)
          return false
        }
      }
      return true
    },
    toggleShow() {
      this.$refs.pic.$el.click()
    },
    getImgUrl(file) {
      return new Promise(resolve => {
        if (file !== undefined) {
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener('load', () => {
            resolve(fr.result)
          })
        }
      })

    },
    getPaymentsGroup(current, record, type = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
        type: type
      };
      return new Promise(resolve => {
        this.fetchPaymentsGroups(data).then(value => resolve(value))
      })

    },
    getAgencies(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgency(data)
            .then(value => {
              resolve(value)
            })
      })

    },
  },
  watch: {
    'agency.password': function () {
      if (this.post || this.agency.password) {
        let regex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].)(?=.*[a-z]).{8,}$/;
        this.pass_val = !regex.test(this.agency.password);
      } else if (!this.post && !this.agency.password) {
        this.pass_val = false
      }
    },
    avatar: async function () {
      this.avatarPreview = await this.getImgUrl(this.avatar)
    },
    'country': async function () {
      this.agency.country = this.country.name
      this.stateList = await this.fetchState(this.country.id)
    },
    'province': async function () {
      this.agency.provinceId = this.province.name
      this.cityList = await this.fetchCity(this.province.id)
    },
    'municipality': function () {
      this.agency.municipalityId = this.municipality.name
    },
    'paymentGroupsValue': function () {
      let temp = [];
      this.paymentGroupsValue.forEach(item => {
        temp.push(item.id)
      })
      this.paymentGroups = temp
    },

  },
  created() {
    this.setLoading()
    this.getPaymentsGroup()
    this.fetchCountry()
        .then(() => {
          if (this.$route.params.id) {
            this.post = false
            this.getAgencies(1, 1, this.$route.params.id)
                .then(value => {
                  // this.agency = value
                  this.agency.email = value.email
                  this.agency.id = value.id
                  this.agency.isActive = value.isActive
                  this.agency.name = value.name
                  this.agency.exchangeRate = value.exchangeRate
                  this.agency.identificationNumber = value.identificationNumber
                  this.agency.phoneNumber = value.phoneNumber
                  this.agency.addressLine2 = value.addressLine
                  this.agency.addressLine3 = value.addressLine
                  this.agency.zipCode = value.zipCode
                  this.agency.avatarPreview = value.profilePicture
                  this.agency.addressLine1 = value.addressLine
                  this.paymentGroupsValue = value.paymentGroups
                  this.country = this.countryList.find(c => c.name === value.country)
                  this.fetchState(this.country.id)
                      .then(states => {
                        this.province = states.find(s => s.name === value.province)
                        this.agency.municipalityId  = value.municipality

                        // this.fetchCity(this.province.id)
                        //     .then(cities => {
                        //       this.agency.municipalityId  = cities.find(c => c.name === value.municipality)
                        //       this.agency.municipalityId = this.municipality
                        //     })
                      })
                })

          }
        }).finally(() => {
      this.setLoading(false)
    })

  }
}
</script>
<style scoped lang="scss">
.form-agency {
  //padding-left: 138px;

  .avatar-load {
    border-radius: 50%;
    min-height: 200px;
  }

  .tag {
    font-weight: bold;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    width: 111px;

    &.is-credentials {
      color: #28BCE8;
      border: 1px solid #28BCE8;

    }

    &.is-data {
      color: #FFFFFF;
      background: #28BCE8;
    }


  }
}

.btn {
  width: 316px;
  height: 58px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;

  &.btn-back {
    border: 1px solid #28BCE8;
    color: #28BCE8;
  }

  &.btn-continue {
    background: #28BCE8;
    color: #FFFFFF;
  }
}

.help {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #C4C4C4;
}

.avatar {
  height: 156px;
  width: 156px;
  background: #DEDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-right: 0 !important;
}

.account-avatar {
  font-size: 100px;

}


</style>